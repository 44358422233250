import { WorklogsApi } from '../../api/worklogs';
import { MainApi } from '../../api/main';

export default {
    namespaced: true,
    state: {
        items: [],
        filters: {
            withErrors: false,
            withUnsynced: false,
        },
        paginationParams: {
            pageCount: 30,
            page: 1,
            limit: 25,
        },
        isOpen: false,
        worklogItem: {},
    },
    getters: {
        isCurrentPage: state => page => state.paginationParams.page === page,
    },
    actions: {
        loadItems: async ({ state, commit }) => {
            const response = await WorklogsApi.getAll({
                filters: state.filters,
                limit: state.paginationParams.limit,
                page: state.paginationParams.page,
            });
            commit('setItems', response.data);
        },
        syncItem: async ({ dispatch }, itemId) => {
            await WorklogsApi.syncItem(itemId);
            dispatch('loadItems');
        },
        deleteItem: async ({ dispatch }, itemId) => {
            await WorklogsApi.deleteItem(itemId);
            dispatch('loadItems');
        },
        pull: async ({ dispatch }) => {
            await MainApi.pull();
            dispatch('notifications/create', {
                message: 'Pulled successfully',
                type: 'success',
            }, { root: true });
            dispatch('loadItems');
        },
        push: async ({ dispatch }) => {
            await MainApi.push();
            dispatch('notifications/create', {
                message: 'Pushed successfully',
                type: 'success',
            }, { root: true });
            dispatch('loadItems');
        },
        changePage: ({ getters, commit, dispatch }, page) => {
            if (getters.isCurrentPage(page)) {
                return;
            }

            commit('setPage', page);
            dispatch('loadItems');
        },
        changeLimit: ({ commit, dispatch }, limit) => {
            commit('setLimit', limit);
            dispatch('loadItems');
        },
        changeFilterWithErrors: ({ state, commit, dispatch }) => {
            commit('setFilters', { ...state.filters, withErrors: !state.filters.withErrors });
            dispatch('loadItems');
        },
        changeFilterWithUnsynced: ({ state, commit, dispatch }) => {
            commit('setFilters', { ...state.filters, withUnsynced: !state.filters.withUnsynced });
            dispatch('loadItems');
        },
        showModal: async ({ commit, dispatch }) => {
            commit('setIsOpen', true);
            dispatch('resetWorklogItem');
        },
        hideModal: async ({ commit, dispatch }) => {
            commit('setIsOpen', false);
            dispatch('resetWorklogItem');
        },
        resetWorklogItem: async ({ commit }) => {
            commit('setWorklogItem', {
                duration: 1,
            });
        },
        saveWorklog: async ({ dispatch }, item) => {
            await WorklogsApi.createItem({
                ...item,
                duration: item.duration * 60,
            });

            dispatch('hideModal');
            dispatch('loadItems');
        },
    },
    mutations: {
        setItems: (state, items) => {
            if (Array.isArray(items)) {
                state.items = items;
                return;
            }

            state.items = items.data;
            state.paginationParams.pageCount = items.pageCount;
        },
        setPage: (state, page) => {
            state.paginationParams.page = page;
        },
        setFilters: (state, filters) => {
            state.filters = filters;
        },
        setLimit: (state, limit) => {
            state.paginationParams.limit = limit;
        },
        setIsOpen: (state, value) => {
            state.isOpen = value;
        },
        setWorklogItem: (state, item) => {
            state.worklogItem = item;
        },
    },
};
