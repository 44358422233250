import { createRouter, createWebHistory } from 'vue-router';
import Project from '../pages/projects/Project';
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'main',
        redirect: '/worklogs',
        meta: { requiresLogin: true },
        component: () => import(/* webpackChunkName: "main" */ '../pages/main/Main'),
        children: [
            {
                path: '/projects',
                name: 'projects',
                component: () => import(/* webpackChunkName: "projects" */ '../pages/projects/Projects.vue'),
                children: [
                    {
                        path: ':id',
                        name: 'project',
                        component: Project,
                    },
                ],
            },
            {
                path: '/worklogs',
                name: 'worklogs',
                component: () => import(/* webpackChunkName: "worklogs" */ '../pages/worklogs/Worklogs.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "users" */ '../pages/users/Users.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/Login.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin) && !store.getters['auth/isAuthenticated']) {
        next({ name: 'login', query: { redirect: to.fullPath } });
    } else {
        next();
    }
});

export default router;
