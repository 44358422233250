<template>
  <div class="container-fluid">
    <div class="box__title">
      <span class="fs-4">Participants</span>
      <button v-if="nonParticipants.length" data-bs-toggle="modal" data-bs-target="#addParticipantModal"
              class="btn btn-primary btn-sm add-user-button">
        Add
      </button>
    </div>
    <div class="box__content">
      <ul class="list-group list-group-flush">
        <li v-for="u in getUsersByProjectID($route.params.id)"
            :key="u.id"
            class="list-group-item project-user">
          {{ u.user.togglUserName }}
          <button @click="removeUserFromProject({projectId: $route.params.id, id: u.id})"
                  class="btn-danger btn-sm user__delete-btn">Remove
          </button>
        </li>
      </ul>
    </div>
  </div>


  <!-- Modal -->
  <div class="modal fade" id="addParticipantModal" tabindex="-1" aria-labelledby="addParticipantModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addParticipantModalLabel">Users</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="list-group list-group-flush">
            <li v-for="u in nonParticipants"
                :key="u.togglUserId"
                class="list-group-item project-user">
              {{ u.togglUserName }}
              <button class="btn-default btn-sm"
                      @click="addUserToProject({projectId: $route.params.id, userId: u.togglUserId})">Добавить
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.box__title {
  padding: 1rem 0;
  display: flex;
}

.box__content {
}

.add-user-button {
  margin-left: 1rem;
}

.project-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user__delete-btn {
  opacity: 0;
}

.project-user:hover .user__delete-btn {
  opacity: 1;
}
</style>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  computed: {
    // смешиваем результат mapGetters с внешним объектом computed
    ...mapGetters('projects', [
      'getUsersByProjectID',
    ]),
    ...mapState({
      nonParticipants(state) {
        return state.users.users.filter(u => !this.getUsersByProjectID(this.$route.params.id).find(i => i.userId === u.togglUserId));
      }
    }),
    ...mapState('users', [
      'users'
    ])
  },
  methods: {
    ...mapActions('projects', [
      'removeUserFromProject',
      'addUserToProject',
      'loadProjectParticipants',
    ]),
    ...mapActions('users', [
      'loadUsers',
    ]),
    fetchData() {
      if (!this.$route.params.id) {
        return;
      }
      this.loadProjectParticipants({projectId: this.$route.params.id});
    }
  },
  watch: {
    $route: 'fetchData'
  },
  created() {
    this.fetchData();
    this.loadUsers();
  }
}

</script>

