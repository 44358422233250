import Client from './client';

export class UsersApi {
    static async getAll() {
        return Client.get('/users');
    }

    static async createUser(item) {
        item.togglUserId = parseInt(item.togglUserId);
        return Client.post('/users', item);
    }

    static async updateUser(item) {
        return Client.put('/users/' + item.togglUserId, item);
    }

    static async removeUser(userId) {
        return Client.delete('/users/' + userId);
    }
}