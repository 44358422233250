import Axios from 'axios';
import store from '@/store';

Axios.defaults.showLoader = true;

const client = Axios.create({
    baseURL: '/api',
});
client.interceptors.request.use(
    async config => {
        if (config.showLoader) {
            await store.dispatch('loader/show');
        }

        const token = store.getters['auth/accessToken'];
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    async error => {
        if (error.config.showLoader) {
            await store.dispatch('loader/done');
        }

        return Promise.reject(error);
    });
client.interceptors.response.use(
    async response => {
        if (response.config.showLoader) {
            await store.dispatch('loader/done');
        }

        return response;
    },
    async error => {
        const { response } = error;
        if (response) {
            if (response.config.showLoader) {
                await store.dispatch('loader/done');
            }

            if (response.data && response.data.message) {
                await store.dispatch('notifications/create', {
                    message: response.data.message,
                    type: 'error',
                });
            }

            if (response.status === 401) {
                await store.dispatch('auth/logout');
            }
        }

        if (error instanceof Axios.Cancel) {
            return {
                cancelled: true,
            };
        }

        return Promise.reject(error);
    });

export default client;
