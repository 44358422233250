<template>
  <div class="loader">
    <div class="spinner-border loader__spinner" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style>
.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  z-index: 100000;
  background-color: rgba(255, 255, 255, 0.5);
}


</style>