import { UsersApi } from '../../api/users';

export default {
    namespaced: true,
    state: {
        users: [],
        userItem: {},
        isOpen: false,
        editMode: false,
    },
    getters: {},
    actions: {
        loadUsers: async ({ commit }) => {
            const response = await UsersApi.getAll();
            commit('setUsers', response.data);
        },
        saveUser: async ({ state, dispatch }, user) => {
            if (state.editMode) {
                await UsersApi.updateUser(user);
            } else {
                await UsersApi.createUser(user);
            }

            dispatch('hideModal');
            dispatch('loadUsers');
        },
        removeUser: async ({ dispatch }, userId) => {
            await UsersApi.removeUser(userId);
            dispatch('loadUsers');
        },
        showModal: async ({ commit }, { user = {}, editMode = false } = {}) => {
            if (user) {
                commit('setUserItem', user);
            }

            commit('setIsOpen', true);
            commit('setEditMode', editMode);
        },
        hideModal: async ({ commit }) => {
            commit('setIsOpen', false);
            commit('setUserItem', {});
            commit('setEditMode', false);
        },
    },
    mutations: {
        setUsers: (state, items) => {
            state.users = items;
        },
        setIsOpen: (state, value) => {
            state.isOpen = value;
        },
        setUserItem: (state, item) => {
            state.userItem = item;
        },
        setEditMode: (state, value) => {
            state.editMode = value;
        },
    },
};
