import Client from './client';

export class ProjectsApi {
    static async getProjects() {
        return Client.get('/projects');
    }

    static async getProjectParticipants(projectId) {
        return Client.get(`/projects/${projectId}/participants`);
    }

    static async addProjectParticipant(projectId, userId) {
        return Client.post('/user-projects', {
            userId,
            projectId,
        });
    }

    static async removeParticipant(id) {
        return Client.delete('/user-projects/' + id);
    }
}