import Client from './client';

export class WorklogsApi {
    static async getAll({ limit, page, filters }) {
        let params = {
            limit,
            page,
            filter: [],
            sort: 'togglStart,DESC',
        };
        if (filters) {
            if (filters.withErrors) {
                params.filter.push('jiraError||$notnull');
            }

            if (filters.withUnsynced) {
                params.filter.push(`isSyncWithJira||$ne||${filters.withUnsynced}`);
            }
        }
        return Client.get('/worklogs', {
            params,
            paramsSerializer: params => {
                let res = [];
                for (const [key, value] of Object.entries(params)) {
                    if (Array.isArray(value)) {
                        res = [...res, ...value.map(i => `${key}=${encodeURIComponent(i)}`)];
                    } else {
                        res.push(`${key}=${encodeURIComponent(value)}`);
                    }
                }

                return res.join('&');
            },
        });
    }

    static async syncItem(itemId) {
        return Client.post(`/worklogs/${itemId}/sync`);
    }

    static async createItem(item) {
        item.togglId = -(new Date()).valueOf();
        item.togglUpdatedAt = item.togglStart;
        return Client.post('/worklogs', item);
    }

    static async deleteItem(itemId) {
        return Client.delete(`/worklogs/${itemId}`);
    }
}
