<template>
  <router-view />
  <transition name='fade'>
    <Loader v-if='loading' />
  </transition>
  <Notifications />
</template>

<style>

html {
  height: 100%;
}

body, .main {
  height: 100%;
  display: flex;
}

#app, .main {
    display: flex;
    width: 100%;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
    opacity: 0;
}


</style>
<script>

import { mapState } from 'vuex';
import Loader from './components/Loader/Loader.vue';
import Notifications from './components/Notifications/Notifications.vue';

export default {
  components: {
    Loader,
    Notifications,
  },
  computed: {
    ...mapState('loader', ['loading']),
  },
};
</script>
