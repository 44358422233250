import { AuthApi } from '../../api/auth';
import jwt_decode from 'jwt-decode';
import router from '../../router';

export default {
    namespaced: true,
    state: {
        accessToken: {},
        user: null,
    },
    getters: {
        isAuthenticated: state => state.accessToken,
        accessToken: state => state.accessToken,
    },
    actions: {
        loginGoogle: async ({ commit }, code) => {
            const { data: { access_token } } = await AuthApi.loginGoogle(code);
            commit('setUser', jwt_decode(access_token));
            commit('setAccessToken', access_token);
            const redirectTo = router.currentRoute.value.query.redirect || '/';
            router.replace(redirectTo);
        },

        logout: ({ commit }) => {
            commit('setUser', {});
            commit('setAccessToken', null);
            router.replace({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } });
        },
    },
    mutations: {
        setUser: (state, user) => {
            state.user = user;
        },
        setAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
        },
    },
};
