<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 100">
    <Toast v-for="(n, index) of items"
           :key="index"
           :message="n.message"
           :type="n.type"
           @close="close(n.id)"
    />
  </div>
</template>
<script>
import Toast from "../Toast/Toast";
import {mapActions, mapState} from "vuex";

export default {
  components: {Toast},

  computed: {
    ...mapState('notifications', {
      items: state => state.items
    })
  },

  methods: {
    ...mapActions('notifications', [
        'close'
    ])
  }
}
</script>