import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import GAuth from 'vue3-google-oauth2';

const gAuthOptions = {
  clientId: '89660055000-68dgr4m9mh9mutto1k9lmbjdjjulu95p.apps.googleusercontent.com',
  scope: 'email profile',
  prompt: 'consent',
  fetch_basic_profile: false,
};

createApp(App)
  .use(store)
  .use(router)
  .use(GAuth, gAuthOptions)
  .mount('#app');
