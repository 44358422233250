import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import projects from './modules/project';
import users from './modules/users';
import loader from './modules/loader';
import worklogs from './modules/worklogs';
import notifications from './modules/notifications';
import auth from './modules/auth';

const dataState = createPersistedState({
    paths: ['auth']
})

export default createStore({
    plugins: [dataState],
    state: {},
    mutations: {},
    actions: {},
    modules: {
        projects,
        users,
        loader,
        worklogs,
        notifications,
        auth,
    },
    getters: {},
});
