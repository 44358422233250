<template>
    <div class='toast show' :class='classByType' role='alert' aria-live='assertive'
         aria-atomic='true'>
        <div class='toast-body d-flex'>
            {{ message }}
            <button @click='close' type='button' class='btn-close me-2 m-auto'
                    :class='closeCloseByType'
                    data-bs-dismiss='toast'
                    aria-label='Close'></button>
        </div>
    </div>
</template>

<script>
const classByType = {
    'success': 'text-white bg-success',
    'error': 'text-white bg-danger',
};
const closeCloseByType = {
    'success': 'btn-close-white',
    'error': 'btn-close-white',
};
export default {
    props: {
        message: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
    },
    emits: ['close'],
    computed: {
        classByType() {
            return classByType[this.type] || '';
        },
        closeCloseByType() {
            return closeCloseByType[this.type] || '';
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
