const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export default {
    namespaced: true,
    state: {
        items: []
    },
    actions: {
        create({commit, dispatch}, {message, type}) {
            const item = {
                generateUUID,
                message,
                type
            };
            commit('create', item);
            setTimeout(() => dispatch('close', item.id), 5000);
        },
        close({commit}, id) {
            commit('close', id);
        },

    },
    mutations: {
        create(state, item) {
            state.items.push(item);
        },
        close(state, id) {
            state.items = state.items.filter(i => i.id !== id);
        }
    }
}