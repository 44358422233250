import Client from './client';

export class MainApi {
    static async getStats() {
        return Client.get('/stats');
    }

    static async pull() {
        return Client.post('/pull');
    }

    static async push() {
        return Client.post('/push');
    }
}