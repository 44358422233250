import {ProjectsApi} from "../../api/projects";

export default {
    namespaced: true,
    state: {
        projects: [],
        usersByProject: {}
    },
    getters: {
        getUsersByProjectID: state => projectId => {
            return state.usersByProject[projectId] || [];
        },
    },
    actions: {
        loadProjects: async ({commit}) => {
            const response = await ProjectsApi.getProjects();
            commit('setProjects', response.data);
        },
        loadProjectParticipants: async ({commit}, {projectId}) => {
            const response = await ProjectsApi.getProjectParticipants(projectId);
            commit('setProjectParticipants', {projectId, items: response.data});
        },
        addUserToProject: async ({dispatch}, {projectId, userId}) => {
            await ProjectsApi.addProjectParticipant(projectId, userId);
            dispatch('loadProjectParticipants', {projectId});
        },
        removeUserFromProject: async ({dispatch}, {projectId, id}) => {
            await ProjectsApi.removeParticipant(id);
            dispatch('loadProjectParticipants', {projectId});
        }
    },
    mutations: {
        setProjects: (state, items) => {
            state.projects = items;
        },
        setProjectParticipants: (state, {projectId, items}) => {
            state.usersByProject[projectId] = items;
        }
    }
}